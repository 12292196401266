@if (dataSource) {
  <nav
    [class.text-sm]="size === 'base'"
    [class.text-xs]="size === 'sm'"
    class="flex max-w-fit overflow-autosss"
  >
    <ol class="mb-0 flex items-center space-x-2" role="list">
      @for (
        item of dataSource;
        track item;
        let first = $first;
        let last = $last
      ) {
        <li>
          @if (!last) {
            <a
              (click)="onClick(item.value)"
              class="cursor-pointer truncate text-black no-underline hover:underline"
            >
              <ng-container *ngTemplateOutlet="itemTmpl"></ng-container>
            </a>
          }
          @if (last) {
            <span class="truncate text-black">
              <ng-container *ngTemplateOutlet="itemTmpl"></ng-container>
            </span>
          }
          <ng-template #itemTmpl>
            @if (item.name === undefined && first) {
              <shared-icon [name]="'house'"></shared-icon>
            }
            @if (item !== undefined || !first) {
              <span [class.font-bold]="last">{{ item.name }}</span>
            }
          </ng-template>
        </li>
        @if (!last) {
          <li>
            <div class="flex items-center text-gray-300">
              <shared-icon [name]="'chevron-right'" [size]="'sm'"></shared-icon>
            </div>
          </li>
        }
      }
    </ol>
  </nav>
}
