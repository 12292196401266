import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { IconComponent } from '@shared/ui-icon';
import { Size } from '@shared/util-size';

import { BreadcrumbDatasource } from './interfaces/breadcrumb-datasource.type';

@Component({
  selector: 'shared-breadcrumb',
  standalone: true,
  imports: [CommonModule, IconComponent],
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Input() dataSource?: BreadcrumbDatasource;
  @Input() size: Size = 'sm';

  @Output() navigate = new EventEmitter<never>();

  public onClick(value: unknown): void {
    this.navigate.emit(value as never);
  }
}
